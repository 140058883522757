import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Row } from "antd";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "src";
import { userSchema } from "src/types/userSchema";
import AppRoutes from "./components/Routes";
import { Logo, Sidebar } from "./components/utils";
import { ConfirmationProvider } from "./context/confirmation";
import { UserProvider } from "./context/user";
import { useResizeObserver } from "./hooks/useResizeObserver";

function App() {
  const { page } = useSelector((state: RootState) => state.bookingReducer);

  const width = useResizeObserver();

  const isMobile = useMemo(() => width <= 500, [width]);

  const { pathname } = useLocation();

  // hide sidebar if the router path is /payments/* or /confirmation
  const hideSidebar = useMemo(() => {
    const displayBottomNav = isMobile && page < 4;
    return (
      displayBottomNav ||
      pathname.includes("/payments") ||
      pathname.includes("/confirmation")
    );
  }, [isMobile, page, pathname]);

  const columnSize = useMemo(() => {
    if (hideSidebar) return 24;
    if (page < 5) {
      return 18;
    }
    if (page === 5) return 12;
    return 24;
  }, [hideSidebar, page]);

  const methods = useForm({ resolver: yupResolver(userSchema) });

  return (
    <div className="App">
      <Row>
        <Col lg={columnSize} xs={24}>
          <div className="content">
            <Logo />
            <br />
            <UserProvider>
              <ConfirmationProvider>
                <FormProvider {...methods}>
                  <AppRoutes />
                </FormProvider>
              </ConfirmationProvider>
            </UserProvider>
          </div>
        </Col>
        {page < 6 && !hideSidebar ? (
          <Col
            lg={24 - columnSize || 24}
            xs={24}
            style={{
              overflow: "auto",
              minHeight: "100%",
              backgroundColor: "#DFF9FF",
            }}
          >
            <Sidebar />
          </Col>
        ) : null}
      </Row>
    </div>
  );
}

export default App;
