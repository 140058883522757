import type { PaymentIntent } from "@stripe/stripe-js";
import { addOnDbValue } from "src/constants/options";
import { initialState } from "../reducers";
import { BookingInput, HttpError, VerifyPaymentInput } from "../types";

export const createPaymentIntent = async (
  booking: BookingInput,
  token?: string
): Promise<PaymentIntent | HttpError> => {
  // replace booking.add_ons with addOnDbValue to get past the 500 character limit
  // set by stripe for their metadata string.
  const addOns = booking.add_ons
    .filter(Boolean)
    .map((addOn) => addOnDbValue[addOn] || "");
  booking.add_ons = addOns;

  let response = await fetch(
    process.env.REACT_APP_API_URL + "/bookings/initialize",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      body: JSON.stringify(booking),
    }
  );

  return await response.json();
};

export const updatePaymentIntent = async (
  paymentIntent: PaymentIntent,
  booking: BookingInput,
  token?: string
): Promise<PaymentIntent | HttpError> => {
  // replace booking.add_ons with addOnDbValue to get past the 500 character limit
  // set by stripe for their metadata string.
  const addOns = booking.add_ons
    .filter(Boolean)
    .map((addOn) => addOnDbValue[addOn] || "");
  booking.add_ons = addOns;

  let response = await fetch(
    process.env.REACT_APP_API_URL +
      `/bookings/payment-intent/${paymentIntent.id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      body: JSON.stringify(booking),
    }
  );

  return await response.json();
};

export const verifyPayment = async ({
  booking,
  payment_type = "cash",
  payment_intent = null,
  payment_method,
  token,
}: VerifyPaymentInput): Promise<
  | {
      data: {
        fullname: string;
        email: string;
        clean_date: string;
        total_amount: number;
      };
    }
  | HttpError
> => {
  if (booking) {
    const addOns = booking.add_ons
      .filter(Boolean)
      .map((addOn) => addOnDbValue[addOn] || "");
    booking.add_ons = addOns;
  }
  let response = await fetch(
    process.env.REACT_APP_API_URL + "/bookings/verify",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      body: JSON.stringify({
        ...booking,
        payment_intent,
        payment_type,
        payment_method,
      }),
    }
  );

  return await response.json();
};

export const createBookingAPI = async (
  booking_input: BookingInput,
  token?: string
): Promise<[any, string | null]> => {
  try {
    let response: any = await fetch(
      process.env.REACT_APP_API_URL + "/booking",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
        body: JSON.stringify({ booking_input }),
      }
    );
    if (response.status === 429) {
      return [null, "Your booking limit has exceeded for today"];
    }
    response = await response.json();
    if (!response.data || response.error) {
      return [null, response.error.message || "Something went wrong.."];
    }
    return [response.data, null];
  } catch (error) {
    console.error(error);
    // @ts-ignore
    return [null, error || "Your booking limit has exceeded for today"];
  }
};

type ContextType = {
  state: typeof initialState;
  email?: string;
  phone?: string;
  contactType?: string;
  fullname?: string;
};

let getFrequency = (occurance: number): string => {
  switch (occurance) {
    case 0:
      return "One Time";
    case 20:
      return "Weekly";
    case 15:
      return "Every 2 weeks";
    case 10:
      return "Every 4 weeks";
    default:
      return "One Time";
  }
};

export const getBookingDetails = ({
  state,
  email,
  phone,
  contactType: contact_type,
  fullname,
}: ContextType): BookingInput => {
  return {
    email,
    fullname,
    bathrooms: parseInt(state.bathrooms, 10),
    bedrooms: state.bedrooms,
    clean_date: state.cleanDate,
    cleaning_type: state.types,
    clean_time: state.isFlexible ? "flexible" : state.cleanTime,
    frequency: getFrequency(state.occurance),
    promocode: state.code,
    add_ons: state.addOns.type.length ? state.addOns.type : [""],
    address: state.address,
    appartment_no: state.appartmentNo,
    // tip: state.tip,
    tip: 0,
    // avoid sending confirmation email if type is "test".
    instructions:
      process.env.REACT_APP_TYPE === "test" ? "test" : state.instructions,
    pets: state.pets.text || state.pets.type,
    phone,
    get_in_type: state.getInType.text || state.getInType.type,
    contact_type,
    total_amount: state.totalPrice,
  };
};
