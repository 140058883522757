import { Button, Layout } from "antd";
import React, { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MobileNavbar from "src/components/utils/MobileNavbar";
import { StyledRadioGroup } from "src/components/utils/RadioGroup";
import {
  bathroomOptions,
  bedroomOptions,
  cleaningTypes,
} from "src/constants/options";
import { CLEANING_TYPE_PRICE } from "src/constants/pricing";
import { VerifyCoupon } from "src/graphql/mutations/coupons";
import { useResizeObserver } from "src/hooks/useResizeObserver";
import { findKeyByValue } from "src/weirdStuff";
import { useMutation } from "urql";
import {
  setBathrooms,
  setBedrooms,
  setOccurance,
  setPromocode,
  setTypes,
} from "../actions";
import { logEvent } from "../analytics/amplitude";
import { openNotificationWithIcon } from "./StepTwo";

const Bedrooms = ({ dispatch, bedrooms }) => {
  return (
    <Fragment>
      <StyledRadioGroup
        size="large"
        id="bedrooms"
        name="bedrooms"
        label="Number of Bedrooms"
        defaultValue={bedrooms}
        style={{ display: "flex" }}
        options={bedroomOptions}
        value={bedrooms}
        onChange={(e) => dispatch(setBedrooms(e.target.value))}
      />
    </Fragment>
  );
};

const Bathrooms = ({ dispatch, bathrooms }) => {
  return (
    <Fragment>
      <StyledRadioGroup
        size="large"
        id="bathrooms"
        name="bathrooms"
        label="Number of Bathrooms"
        defaultValue={String(bathrooms)}
        options={bathroomOptions}
        value={String(bathrooms)}
        style={{ display: "flex" }}
        onChange={(e) => dispatch(setBathrooms(e.target.value))}
      />
    </Fragment>
  );
};

const CleaningTypes = ({ dispatch, types, isMobile }) => (
  <Fragment>
    <StyledRadioGroup
      id="cleaningType"
      name="cleaningType"
      size="large"
      defaultValue={types}
      options={cleaningTypes}
      label="Type of cleaning"
      value={types}
      style={
        isMobile
          ? {
              flexWrap: "wrap",
            }
          : {}
      }
      onChange={(e) => dispatch(setTypes(e.target.value))}
    />
  </Fragment>
);

const MemoizedBedrooms = React.memo(Bedrooms);
const MemoizedBathrooms = React.memo(Bathrooms);
const MemoizedCleaningTypes = React.memo(CleaningTypes);

export default function StepOne(props) {
  const dispatch = useDispatch();
  const { bedrooms, bathrooms, types } = useSelector(
    // @ts-ignore
    (state) => state.bookingReducer
  );

  const [, verifyCoupon] = useMutation(VerifyCoupon);

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("d");
    if (!code || code == null) {
      return;
    }
    verifyCoupon({ code }).then((result) => {
      if (result.error || !result.data) {
        logEvent("User entered an invalid promocode", { promocode: code });
        openNotificationWithIcon("error", "Invalid promocode", "");
        dispatch(setPromocode("", 0));
      } else {
        dispatch(setOccurance(0));
        logEvent("User applied promocode", { promocode: code });
        openNotificationWithIcon(
          "success",
          "Promocode applied",
          "You just availed a discount of $" +
            result.data.verifyCoupon?.discount +
            "!"
        );
        dispatch(setPromocode(code, result.data?.verifyCoupon?.discount));
      }
    });
  }, []);

  const width = useResizeObserver();

  const isMobile = width <= 500;

  const nextAction = useCallback(() => {
    logEvent("User selected bedrooms, bathrooms and cleaning type...", {
      bedrooms,
      bathrooms,
    });
    // @ts-ignore
    props.changePage(2);
  }, [bathrooms, bedrooms, props]);

  const cleaningTypeText = findKeyByValue(CLEANING_TYPE_PRICE, types);

  return (
    <Layout className="step-layout mobile-step-layout">
      <h1 className="semi-bold">Book a cleaning today</h1>
      <br />
      <MemoizedBedrooms dispatch={dispatch} bedrooms={bedrooms} />
      <br />
      <MemoizedBathrooms dispatch={dispatch} bathrooms={bathrooms} />
      <br />
      <MemoizedCleaningTypes
        isMobile={isMobile}
        dispatch={dispatch}
        types={types}
      />
      <br />
      <span className="center mt-2">
        {cleaningTypeText === "Standard"
          ? "Our basic clean. We cover all Checklist items. Extras optional. Best for those who clean regularly."
          : cleaningTypeText === "Special Mission"
          ? "Our most popular. We cover all Checklist items with more time. Best for those who haven’t cleaned in a while."
          : cleaningTypeText === "Super Clean"
          ? "We’ve got work to do. We cover all Checklist items, plus our extras. Best for those who rarely clean."
          : "Moving in / out can be a hassle. We cover all Checklist items, plus our extras. Best for those who are moving in / out."}
      </span>
      <br />
      {isMobile ? (
        <MobileNavbar nextAction={nextAction} />
      ) : (
        <Button onClick={nextAction} type="primary" className="next-button">
          Next
        </Button>
      )}
      <br />
    </Layout>
  );
}
