import { Col, InputNumber, Row } from "antd";
import { memo, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setTip } from "src/actions";
import { StyledRadioGroup } from "./RadioGroup";

const tipOptions = [
  {
    label: "$5",
    value: "5",
  },
  {
    label: "$10",
    value: "10",
  },
  {
    label: "$15",
    value: "15",
  },
  {
    label: "Other",
    value: "other",
  },
];

function Tip() {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const dispatch = useDispatch();

  const watchTip = watch("tipOptions");
  const watchTipAmount = watch("tipAmount");

  const other = watchTip === "other";

  useEffect(() => {
    // Update the tip state so that we can display this
    // amount in the order summary section
    if (watchTip) {
      if (!other) {
        dispatch(setTip(Number(watchTip)));
      } else {
        dispatch(setTip(Number(watchTipAmount)));
      }
    }
  }, [dispatch, other, watchTip, watchTipAmount]);

  return (
    <>
      <Row>
        <Col xs={24}>
          <label htmlFor="tipOptions">Leave a tip (Optional)</label>
          <Controller
            name="tipOptions"
            control={control}
            render={({ field }) => {
              return (
                <StyledRadioGroup
                  size="small"
                  id="tipOptions"
                  {...field}
                  options={tipOptions}
                />
              );
            }}
          />
          {other && (
            <Controller
              name="tipAmount"
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <InputNumber
                      autoFocus
                      className="input"
                      placeholder="Enter tip amount"
                      formatter={(value) =>
                        // regex to remove non-numeric characters
                        value &&
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      size="large"
                      min={0}
                      max={100}
                      step={5}
                      {...field}
                    />
                    {errors["tipAmount"] && (
                      <small
                        style={{
                          color: "red",
                          display: "block",
                          marginBottom: 5,
                        }}
                      >
                        {errors["tipAmount"].message}
                      </small>
                    )}
                  </>
                );
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default memo(Tip);
