import { SmileOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logEvent } from "src/analytics/amplitude";
import { getDashboardURL } from "src/weirdStuff";
import { useConfirmation } from "../context/confirmation";

const Confirmation = ({ changePage }) => {
  const { clean_date, total_amount, email } = useConfirmation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!clean_date || !total_amount || !email) {
      changePage(1);
      // @ts-expect-error gtag might be undefined
      gtag?.("event", "Booking_Failed");
      debugger;
      navigate("/");
    } else {
      // @ts-expect-error gtag might be undefined
      gtag?.("event", "Booking_Done");
      logEvent("User was directed to the confirmation page", {
        email,
        clean_date,
        total_amount,
      });
    }
    window.onbeforeunload = null;
  }, [clean_date, total_amount, changePage, email, navigate]);

  return (
    <div>
      <Result
        icon={<SmileOutlined twoToneColor="#00b6de" />}
        status="success"
        title="Reservation confirmed!"
        subTitle={`Your cleaning and disinfection service is ready for you!
          We'll see you on ${moment(clean_date).format("ll")}`}
        extra={
          <div>
            <div className="button-group center">
              <Button
                size="large"
                style={{ borderRadius: 5 }}
                type="primary"
                href={getDashboardURL("booking-app", "confirmation-page")}
                target="_blank"
                rel="noopener noreferrer"
              >
                Manage bookings
              </Button>
              <Button
                type="link"
                size="large"
                href="https://heyhomero.com/contact"
                className="link"
              >
                Contact support
              </Button>
            </div>
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={require("../assets/cleaningmethods.pdf").default}
            >
              Learn more about our cleaning guidelines
            </a>
          </div>
        }
      />
    </div>
  );
};

export default Confirmation;
