import Input, { InputProps } from "antd/es/input/Input";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

interface FormInputProps extends InputProps {
  defaultValue?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label?: string;
  id: string;
  optional?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export default function FormInput({
  defaultValue,
  onChange,
  placeholder = "",
  type = "text",
  size = "large",
  label,
  id,
  optional = false,
  onBlur,
  ...rest
}: FormInputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <fieldset>
      <label htmlFor={id} className="mt-2">
        {label} {optional ? " (Optional)" : ""}
      </label>
      <Controller
        name={id}
        control={control}
        render={({
          field: { onChange: fieldOnChange, onBlur: fieldOnBlur, ...fields },
        }) => (
          <Input
            id={id}
            className="input"
            size={size}
            onChange={(e) => {
              if (onChange) onChange(e);
              fieldOnChange(e);
            }}
            onBlur={(e) => {
              if (onBlur) onBlur(e);
              fieldOnBlur();
            }}
            aria-invalid={!!errors[id]}
            type={type}
            defaultValue={defaultValue}
            placeholder={placeholder}
            style={{
              borderColor: errors[id] ? "red" : "#d9d9d9",
            }}
            {...rest}
            {...fields}
          />
        )}
      />
      {errors?.[id] ? (
        <small style={{ color: "red", display: "block", marginBottom: 5 }}>
          {errors?.[id]?.message}
        </small>
      ) : null}
    </fieldset>
  );
}
