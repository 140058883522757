import { memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src";
import styled from "styled-components";

function MostPopularTag() {
  const { types } = useSelector((state: RootState) => state.bookingReducer);

  if (Number(types) > 190) return null;

  return (
    <div style={{ position: "relative" }}>
      <Tag>
        <img
          src={require("../../assets/icons/arrow.svg").default}
          alt="Arrow pointing downwards"
        />
        <span className="semi-bold">Most popular</span>
      </Tag>
    </div>
  );
}

export default memo(MostPopularTag);

const Tag = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  position: absolute;
  left: 52.5%;
  top: -0.75rem;
  color: var(--blue-300);
  font-family: Poppins, sans-serif;
  img {
    margin-top: 0.5rem;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;
