import amplitude from "amplitude-js";

export const initAmplitude = () => {
  amplitude.getInstance().init("30e39942bfc1aa40d657251459e82cf1");
};

export const logEvent = (eventName: string, eventData: any = undefined) => {
  if (process.env.NODE_ENV === "development") return;
  amplitude.getInstance().logEvent(eventName, eventData);
};
