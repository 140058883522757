import { Layout } from "antd";
import React from "react";
import styled from "styled-components";
import LogoImg from "../../assets/logo.svg";

const LogoContainer = styled.div`
  h1 > a {
    font-family: "Palanquin" !important;
    color: #323593;
    font-weight: bold;
    font-size: 2rem;
    display: block;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  h1 {
    margin-bottom: 0;
  }
  span {
    color: #03a4ff;
  }
`;

export const Logo = () => {
  return (
    <Layout className="step-layout">
      <LogoContainer>
        <h1>
          <a href="https://heyhomero.com" title="Homero's website">
            <img src={LogoImg} alt="Homero Logo" title="Homero Logo" />
          </a>
        </h1>
        <span>Happiness is a clean home.</span>
      </LogoContainer>
    </Layout>
  );
};
