import Radio, { RadioGroupProps } from "antd/es/radio";
import Tag from "antd/es/tag";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

interface Props extends RadioGroupProps {
  options: {
    label: string;
    value: string | number;
    tag?: string | null;
    subText?: string | null;
  }[];
  label?: string;
  id: string;
}

export default function RadioGroup({
  onChange,
  options,
  size,
  label,
  id,
  className = "types",
  defaultValue,
  ...rest
}: Props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <fieldset>
      {label && (
        <label htmlFor={id} className="mt-2">
          {label}
        </label>
      )}
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange: fieldOnChange, ...fields } }) => (
          <Radio.Group
            size={size}
            buttonStyle="solid"
            className={className}
            onChange={(e) => {
              if (onChange) onChange(e);
              fieldOnChange(e);
            }}
            style={{
              borderColor: errors[id] ? "red" : "#d9d9d9",
              ...rest.style,
            }}
            {...rest}
            {...fields}
          >
            {options.map((option) => (
              <Radio.Button
                key={option.value}
                value={option.value}
                className="button"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {option.label}
                {option.tag && (
                  <>
                    &nbsp;&nbsp;<Tag color="#00b6de">{option.tag}</Tag>
                  </>
                )}
                {option.subText ? (
                  <div style={{ opacity: 0.9, fontSize: "0.9rem" }}>
                    {option.subText}
                  </div>
                ) : null}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      />
      {errors?.[id] && (
        <small style={{ color: "red", display: "block", marginBottom: 5 }}>
          {errors?.[id]?.message}
        </small>
      )}
    </fieldset>
  );
}

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => (props.size === "large" ? "8px" : "1px")};
  margin-bottom: 1rem;
  gap: 1rem;
  border-left: none !important;

  label.button {
    border: 1px solid #e2e2e2;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: ${(props) => (props.size === "large" ? "1rem" : "0.45rem")};
  }

  @media (max-width: 500px) {
    label.button {
      padding: 0.5rem;
    }
    gap: 0.5rem;
  }
`;
