import { CardElement } from "@stripe/react-stripe-js";
import { Col, Row } from "antd";
import { memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src";
import { StyledRadioGroup } from "./RadioGroup";

export function CheckoutForm({ cash = false }) {
  const { occurance } = useSelector((state: RootState) => state.bookingReducer);

  return (
    <>
      <Row
        style={{ width: "100%", marginBottom: "1rem", rowGap: "1rem" }}
        gutter={{ xs: 0, sm: 0, md: 16, lg: 16 }}
      >
        <Col xs={24} lg={12}>
          <StyledRadioGroup
            id="paymentType"
            size="small"
            label="Payment Type"
            value="stripe"
            defaultValue="stripe"
            style={{ outline: "none" }}
            options={[
              { label: "Pay by Card", value: "stripe" },
              { label: "Pay via Cash", value: "cash" },
            ]}
          />
        </Col>
        {!cash && (
          <Col xs={24} lg={12}>
            <label
              htmlFor="cardDetails"
              style={{ marginBottom: 5, display: "block" }}
            >
              Card Details
            </label>
            <CardElement
              options={{
                hidePostalCode: false,
                style: {
                  base: {
                    color: "#32325d",
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "red",
                    iconColor: "red",
                  },
                },
              }}
            />
            <small style={{ color: "grey", marginTop: 6, display: "block" }}>
              Secured by Stripe.{" "}
              {occurance > 0
                ? `By providing your card information, you allow
              Homero to charge your card for future payments in accordance with
              our terms.`
                : ""}
            </small>
          </Col>
        )}
        <br />
      </Row>
    </>
  );
}

export default memo(CheckoutForm);
