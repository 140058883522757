import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const DASHBOARD_URL =
  process.env.REACT_APP_DASHBOARD_URL || "http://localhost:3000";

const keys = ["email", "fullname"];

export function useMessageListener() {
  const { setValue } = useFormContext();

  useEffect(() => {
    const listener = (event) => {
      try {
        if (event.origin !== DASHBOARD_URL) return;

        const parsedData = JSON.parse(event.data);
        if (parsedData.code !== "user") return;

        keys.forEach((key) => {
          setValue(key, parsedData[key], {
            shouldValidate: true,
          });
        });
      } catch (e) {
        // console.log(e);
      }
    };
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [setValue]);
}
