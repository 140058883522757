import { useState, useEffect } from "react";

export const useResizeObserver = () => {
  let [width, setWidth] = useState(window.screen.width);

  const resizeFunction = () => {
    setWidth(window.screen.width);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeFunction);
    return () => {
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);

  return width;
};
