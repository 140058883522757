import Button from "antd/es/button";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Layout from "antd/es/layout";
import notification from "antd/es/notification";
import Row from "antd/es/row";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src";
import AddOns from "src/components/utils/AddOns";
import FormInput from "src/components/utils/FormInput";
import MobileNavbar from "src/components/utils/MobileNavbar";
import MostPopularTag from "src/components/utils/MostPopularTag";
import { StyledRadioGroup } from "src/components/utils/RadioGroup";
import {
  frequencyOptions,
  getInTypeOptions,
  petOptions,
} from "src/constants/options";
import { useResizeObserver } from "src/hooks/useResizeObserver";
import { useMutation } from "urql";
import {
  setAddons,
  setAddress,
  setAppartment,
  setGetInType,
  setInstructions,
  setOccurance,
  setPets,
  setPromocode,
} from "../actions";
import { VerifyAddress } from "../graphql/mutations/address";

const openNotificationWithIcon = (type, title, description) => {
  notification[type]({
    message: title,
    description,
  });
};

const StepThree = (props) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const {
    occurance,
    appartmentNo,
    address,
    addOns,
    getInType,
    pets,
    instructions,
    types,
  } = useSelector((state: RootState) => state.bookingReducer);

  const width = useResizeObserver();
  const isMobile = width <= 500;

  const [, verifyAddress] = useMutation(VerifyAddress);

  const updateAddOns = useCallback(
    (addOns) => {
      dispatch(setAddons(addOns));
    },
    [dispatch]
  );

  const showFrequency = Number(types) <= 190;

  const next = () => {
    setLoading(true);
    let errorTitle = "Invalid data";
    let errorMessage = "Please fill all the fields";
    if (instructions.length >= 500) {
      setLoading(false);
      errorTitle = "Invalid instructions";
      errorMessage =
        "Please provide instructions with less than 500 characters";
      openNotificationWithIcon("error", errorTitle, errorMessage);
    } else if (address.trim().length && getInType && pets) {
      verifyAddress({ address }).then((result) => {
        if (result.error || !result.data.verifyAddress.isValid) {
          setLoading(false);
          errorTitle = "Invalid address";
          errorMessage =
            "Please provide a valid address. Our services are currently available in Brooklyn, Manhattan and Queens";
          openNotificationWithIcon("error", errorTitle, errorMessage);
        } else {
          result?.data?.verifyAddress?.zip &&
            dispatch(
              setAddress(address + ", " + result?.data?.verifyAddress?.zip)
            );
          props.changePage(4);
          return;
        }
      });
    } else {
      setLoading(false);
      openNotificationWithIcon("error", errorTitle, errorMessage);
    }
  };

  const prev = () => {
    props.changePage(2);
  };

  return (
    <Layout className="step-layout mobile-step-layout">
      {showFrequency && (
        <>
          <h1 className="semi-bold">How often should we clean?</h1>
          <p>
            Recurring cleaning - bookings will be scheduled automatically based
            on the frequency you select.
          </p>
        </>
      )}
      <br />
      {showFrequency && <MostPopularTag />}
      {showFrequency && (
        <StyledRadioGroup
          size="large"
          id="occurance"
          name="occurance"
          label="Recurring cleaning"
          options={frequencyOptions}
          defaultValue={occurance}
          onChange={(e) => {
            dispatch(setOccurance(e.target.value));
            dispatch(setPromocode("", 0));
          }}
          style={
            isMobile
              ? {
                  flexWrap: "wrap",
                }
              : {}
          }
        />
      )}
      {showFrequency && <br />}
      {showFrequency ? (
        <span className="semi-bold" style={{ fontSize: "1rem" }}>
          Where would you like us to clean?
        </span>
      ) : (
        <h1 className="semi-bold">Where would you like us to clean?</h1>
      )}
      <br />
      <Row gutter={16}>
        <Col lg={12} xs={24}>
          <span className="mt-2">Address</span> <br />
          <Input
            className="input"
            size="large"
            defaultValue={address}
            onChange={(event) => dispatch(setAddress(event.target.value))}
            placeholder="123 Madison St, New York"
          />
        </Col>
        <Col lg={12} xs={24}>
          <span className="mt-2">
            Appartment No. <small className="optional">(Optional)</small>
          </span>{" "}
          <br />
          <Input
            className="input"
            size="large"
            defaultValue={appartmentNo}
            onChange={(event) => dispatch(setAppartment(event.target.value))}
            placeholder="1023"
          />
        </Col>
      </Row>
      <br />
      <AddOns defaultValue={addOns.type} onChange={updateAddOns} />
      <br />
      <StyledRadioGroup
        size="large"
        defaultValue={getInType.type}
        value={getInType.type}
        id="getInType"
        name="getInType"
        label="How will we get in?"
        options={getInTypeOptions}
        style={
          isMobile
            ? {
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
              }
            : {}
        }
        onChange={(e) => {
          dispatch(setGetInType(e.target.value));
        }}
      />
      {getInType.type === "Hidden Key" || getInType.type === "Other" ? (
        <FormInput
          id="getInTypeOther"
          name="getInTypeOther"
          placeholder={
            getInType.type === "Hidden Key"
              ? "Where is it?"
              : "How do we get in?"
          }
          defaultValue={getInType.text}
          onChange={(e) =>
            dispatch(setGetInType(getInType.type, e.target.value))
          }
        />
      ) : null}
      <br />
      <StyledRadioGroup
        size="large"
        defaultValue={pets.type}
        id="pets"
        name="pets"
        style={
          isMobile
            ? { display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }
            : {}
        }
        label="Do you have any pets?"
        value={pets.type}
        onChange={(e) => dispatch(setPets(e.target.value))}
        options={petOptions}
      />
      {pets.type === "Others" ? (
        <FormInput
          size="large"
          id="petsOther"
          name="petsOther"
          defaultValue={pets.text}
          placeholder="What types of pets? Some of our cleaners have pet allergies."
          onChange={(e) => dispatch(setPets("Others", e.target.value))}
        />
      ) : null}
      <br />
      <span className="mt-2">
        Instructions for Homero and his team{" "}
        <small className="optional">(Optional)</small>
      </span>
      <TextArea
        maxLength={50}
        size="large"
        rows={3}
        style={{ borderRadius: 5 }}
        className="input"
        defaultValue={instructions}
        placeholder="Leave some instructions for our cleaners"
        onChange={(e) => dispatch(setInstructions(e.target.value))}
      />
      <br />
      {isMobile ? (
        <MobileNavbar loading={loading} nextAction={next} prevAction={prev} />
      ) : (
        <div className="button-group center">
          <Button
            onClick={() => props.changePage(2)}
            type="primary"
            className="next-button back-button"
          >
            Back
          </Button>
          <Button onClick={() => next()} type="primary" className="next-button">
            {loading ? "Please wait..." : "Next"}
          </Button>
        </div>
      )}
      <br />
    </Layout>
  );
};

export default StepThree;
