import { ChangeEvent } from "react";
import { DASHBOARD_URLS } from "src/constants/url";
import { Callback } from "src/types";

/* let validZips = [
  "212",
  "315",
  "332",
  "347",
  "518",
  "631",
  "646",
  "680",
  "718",
  "838",
  "929",
  "934",
  ]; */

export const validateEmail = (email: string): boolean => {
  let re: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

export const validatePhone = (phone?: string): boolean => {
  if (!phone) return false;
  if (phone.trim().length !== 16) return false;
  phone = phone.replace(/\D/g, "");
  if (isNaN(parseInt(phone, 10))) return false;
  //  let zip = phone.substring(0, 3);
  //  if (validZips.indexOf(zip) === -1) return false;

  return true;
};

const isModifierKey = (event) => {
  const key = event.keyCode;
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  );
};

export const formatToPhone = (event: ChangeEvent<HTMLInputElement>) => {
  if (isModifierKey(event)) {
    return;
  }

  const target = event.target;
  const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    target.value = `(${zip}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    target.value = `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    target.value = `(${zip}`;
  }
  return target.value;
};

export function findKeyByValue(object: Record<string, any>, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

// typed debounce function
export const debounce = <T extends Callback>(
  callback: T,
  wait: number,
  immediate: boolean = false
) => {
  let timeout: NodeJS.Timeout | null;
  return (...args: any[]) => {
    const later = () => {
      timeout = null;
      if (!immediate) callback(...args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout!);
    timeout = setTimeout(later, wait);
    if (callNow) callback(...args);
  };
};

export function getDashboardURL(utm_source = "", utm_campaign = "") {
  const subdomain = window.location.hostname.split(".")[0];
  const url = new URL(DASHBOARD_URLS[subdomain]);
  url.searchParams.set("utm_source", utm_source);
  url.searchParams.set("utm_campaign", utm_campaign);
  return url.href;
}

export { createBookingAPI } from "./api";
