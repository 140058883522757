export const ADD_ON_PRICE = {
  organizing: "40",
  oven: "35",
  fridge: "35",
  cabinets: "35",
  windows: "37",
  laundry: "37",
};

export const CLEANING_TYPE_PRICE = {
  Standard: "108",
  "Special Mission": "158",
  "Super Clean": "263",
  "Move In / Out": "248",
};
