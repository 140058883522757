import React from "react";

const initialState = {
  name: "",
  email: "",
  phone: "",
  contactType: "",
};

const userContext = React.createContext({
  ...initialState,
  onUserUpdate: (_type: string, _value: any) => {},
});

export const UserProvider = ({ children }) => {
  const value = useUserValue();
  return <userContext.Provider value={value}>{children}</userContext.Provider>;
};

export const useUser = () => {
  return React.useContext(userContext);
};

const userReducer = (
  state: typeof initialState,
  action: {
    type: string;
    payload: any;
  }
): typeof initialState => {
  switch (action.type) {
    case "email":
      return {
        ...state,
        email: action.payload,
      };
    case "phone":
      return {
        ...state,
        phone: action.payload,
      };
    case "name":
      return {
        ...state,
        name: action.payload,
      };
    case "contact":
      return {
        ...state,
        contactType: action.payload,
      };
    default:
      throw new Error("Invalid action type");
  }
};

const useUserValue = (): {
  name: string;
  email: string;
  phone: string;
  contactType: string;
  onUserUpdate: (type: string, payload: any) => void;
} => {
  const [state, dispatch] = React.useReducer(userReducer, initialState);

  const onUserUpdate = React.useCallback(
    (type: string, value) => {
      dispatch({ type, payload: value });
    },
    [dispatch]
  );

  const { name, contactType, email, phone } = state;

  return {
    name,
    contactType,
    email,
    phone,
    onUserUpdate,
  };
};
