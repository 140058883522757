import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "antd";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setToken } from "src/actions";
import Payments from "src/components/Payments";
import FormInput from "src/components/utils/FormInput";
import { SEND_OTP, VERIFY_OTP } from "src/graphql/mutations/auth";
import { GET_USER_CREDITS } from "src/graphql/queries/user";
import { useClient, useMutation } from "urql";

export const contactOptions = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Call",
    value: "call",
  },
  {
    label: "Email",
    value: "email",
  },
];

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);

function StepFour(props) {
  const client = useClient();

  const dispatch = useDispatch();

  const [, sendOTP] = useMutation(SEND_OTP);
  const [, verifyOTP] = useMutation(VERIFY_OTP);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [otpState, setOTPState] = useState<"SEND" | "VERIFY">("SEND");

  const user = useRef<{
    email: string;
    credits: number;
    authType?: "email" | "google-oauth2" | null;
    otp: string | null;
  }>({ email: "", credits: 0, authType: null, otp: null });

  // Check if a user account exists, if so, show the user's credits and ask them to login to use them.
  async function checkEmail(email: string) {
    console.log("Checking email...", email);

    // await client
    const { data, error } = await client
      .query(GET_USER_CREDITS, {
        email,
      })
      .toPromise();

    if (error) return console.error(error);

    if (data.user.credits > 0) {
      user.current = {
        email,
        credits: data.user.credits,
        authType: data.user.auth_type,
        otp: null,
      };
      setShowModal(true);
    }
  }

  async function getOTP() {
    console.log("Sending OTP...", user.current.email);

    setLoading(true);
    const { data, error } = await sendOTP({
      email: user.current.email,
      appType: "booking",
    });

    setLoading(false);

    if (error) return console.error(error);
    if (data.otp?.email) {
      setOTPState("VERIFY");
    }
  }

  async function handleOTP() {
    console.log("Verifying OTP...", user.current.email);
    setLoading(true);
    const { data, error } = await verifyOTP({
      email: user.current.email,
      otp: user.current.otp,
    });

    setLoading(false);

    if (error) {
      console.error(error);
      setError(error.message);
    }

    if (data?.otp?.access_token) {
      dispatch(setToken(data.otp.access_token, user.current.credits));
      setShowModal(false);
    }
  }

  const okText =
    user?.current?.authType === "email"
      ? `${otpState === "SEND" ? "Get" : "Verify"} OTP`
      : "Verify Email";

  return (
    <Elements
      stripe={stripePromise}
      options={{
        appearance: {
          theme: "stripe",
        },
      }}
    >
      <Payments checkEmail={checkEmail} {...props} />
      {/* TODO: refactor the modal and move it to a separate component. */}
      <Modal
        title="Login to use credits"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        okText={okText}
        okButtonProps={{
          loading,
        }}
        onOk={otpState === "SEND" ? getOTP : handleOTP}
      >
        <p>
          You have ${user.current.credits} credits available. Verify your email
          to use them.
        </p>
        {otpState === "VERIFY" && (
          <div>
            <p id="otp-label">
              Enter the OTP sent to <strong>{user.current.email}</strong>
            </p>
            <FormInput
              type="tel"
              id="otp"
              aria-labelledby="otp-label"
              placeholder="Enter OTP"
              size="large"
              onChange={(e) => (user.current.otp = e.target.value)}
            />
            {error && (
              <small style={{ color: "red" }}>
                {error}
                {error?.includes("expired") && (
                  <button
                    onClick={getOTP}
                    style={{
                      all: "unset",
                      marginLeft: 5,
                      color: "var(--blue-300)",
                      fontWeight: "semibold",
                      textDecoration: "underline",
                    }}
                  >
                    Retry
                  </button>
                )}
              </small>
            )}
          </div>
        )}
      </Modal>
    </Elements>
  );
}

export default StepFour;
