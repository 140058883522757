import { gql } from "urql";

export const GET_USER_CREDITS = gql`
  query GetUserCredits($email: String!) {
    user: userByEmail(email: $email) {
      credits
      auth_type
    }
  }
`;
