import { validatePhone } from "src/weirdStuff";
import * as yup from "yup";
export const userSchema = yup.object().shape({
  fullname: yup.string().required("Please enter your full name"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
  phone: yup
    .string()
    .test("phone", "Please enter a valid phone number", (value) =>
      validatePhone(value)
    ),
  contactType: yup
    .string()
    .required(
      "Please tell us how to reach you.. via text, email or a phone call"
    ),
  paymentType: yup.string().oneOf(["stripe", "cash"]),
  terms: yup
    .boolean()
    .oneOf([true, null])
    .required("Please accept the terms and conditions"),
  tipOptions: yup.string().oneOf(["5", "10", "15", "other"]),
  tipAmount: yup.number().when("tipOptions", {
    is: "other",
    then: yup
      .number()
      .min(0, "Please enter a valid amount")
      .max(100, "You can only tip up to $100"),
  }),
});
