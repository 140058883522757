import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { useMessageListener } from "src/hooks/useMessageListener";
import { setPage } from "../actions";
import { useScrollToTop } from "../hooks/useScrollToTop";
import Confirmation from "../pages/Confirmation";
import StepFour from "../pages/StepFour";
import StepOne from "../pages/StepOne";
import StepThree from "../pages/StepThree";
import StepTwo from "../pages/StepTwo";
import VerifyPayments from "../pages/VerifyPayment";
import Payments from "./Payments";

const pages = {
  1: StepOne,
  2: StepTwo,
  3: StepThree,
  4: StepFour,
  5: Payments,
};

const NotFound = () => <div>Page not found.. Try again later.</div>;

export default function AppRoutes(props: any) {
  const dispatch = useDispatch();
  //@ts-ignore
  const { page } = useSelector((state) => state.bookingReducer);

  useScrollToTop(page);

  const changePage = useCallback(
    (page) => {
      dispatch(setPage(page));
    },
    [dispatch]
  );

  const Component = pages[page] || NotFound;

  useMessageListener();

  return (
    <Routes>
      <Route
        path="/confirmation"
        element={<Confirmation changePage={changePage} />}
      />
      <Route path="/payments/verify" element={<VerifyPayments />} />
      <Route path="/" element={<Component changePage={changePage} />} />
    </Routes>
  );
}
