import { useEffect } from "react";

/**
 * a custom hook to scroll to the top of the page when user visits a new page.
 * @param {number} page page number
 */
export const useScrollToTop = (page: number) => {
  return useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
};
