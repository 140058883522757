import Divider from "antd/es/divider";
import moment from "moment";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src";
import { CLEANING_TYPE_PRICE } from "src/constants/pricing";
import { findKeyByValue } from "src/weirdStuff";
import { SummaryContainer } from ".";

export const OrderSummary = ({ bathrooms, bedrooms, types }) => {
  const {
    isFlexible,
    occurance,
    address,
    appartmentNo,
    page,
    addOns,
    cleanDate,
    cleanTime,
  } = useSelector((state: RootState) => state.bookingReducer);

  const getAddOns = useCallback(() => addOns, [addOns]);

  return (
    <SummaryContainer>
      <h1 data-testid="summary header">Order Summary</h1>
      <br />
      <p className="flex justify-content-between">
        <span>Bedrooms:</span>
        <span className="blue">{bedrooms === "0" ? "Studio" : bedrooms}</span>
      </p>
      <p className="flex justify-content-between">
        <span>Bathrooms:</span>
        <span className="blue">{bathrooms}</span>
      </p>
      <Divider style={{ color: "#B1DCE5" }} />
      <p className="flex justify-content-between">
        <span>Cleaning Type:</span>
        <span className="blue">
          {findKeyByValue(CLEANING_TYPE_PRICE, types)}
        </span>
      </p>
      {cleanDate ? (
        <>
          <Divider style={{ color: "#B1DCE5" }} />
          <p className="flex justify-content-between">
            <span style={{ whiteSpace: "nowrap" }}>Cleaning Date:</span>
            <span className="blue right">
              {moment(cleanDate ?? "").format("MMM Do")}
              <br />
              <small>
                {isFlexible
                  ? "Homero will arrive between 9am and 4pm"
                  : cleanTime
                  ? `Homero will arrive at ${cleanTime}`
                  : null}
              </small>
            </span>
          </p>
        </>
      ) : null}
      {page >= 3 ? (
        <>
          <Divider style={{ color: "#B1DCE5" }} />
          <p className="flex justify-content-between">
            <span>Frequency:</span>
            <span className="blue right">
              {occurance === 10
                ? "Every 4 weeks"
                : occurance === 15
                ? "Every 2 weeks"
                : occurance === 20
                ? "Weekly"
                : "One Time"}
            </span>
          </p>
        </>
      ) : null}
      {address?.trim().length > 0 ? (
        <>
          <Divider style={{ color: "#B1DCE5" }} />
          <p className="flex justify-content-between">
            <span>Address:</span>
            <span className="blue right break-word">
              {address ?? ""}
              <br />
              {/* @ts-ignore */}
              <small>{appartmentNo ?? null}</small>
            </span>
          </p>
        </>
      ) : null}

      {/* @ts-ignore */}
      {getAddOns()?.type?.length > 0 ? (
        <>
          <Divider style={{ color: "#B1DCE5" }} />
          <p className="flex justify-content-between">
            <span style={{ whiteSpace: "nowrap" }}>Add Ons:</span>
            {/* @ts-ignore */}
            <span
              className="flex-n"
              style={{ float: "right", textAlign: "right" }}
            >
              {addOns?.type?.map((t: string, i: number, a: string[]) => (
                <span key={i} className="blue inline ">
                  {t.replace("Inside ", "")}
                  {i !== a.length - 1 ? ", " : ""}
                </span>
              ))}
            </span>
          </p>
        </>
      ) : null}
    </SummaryContainer>
  );
};
