import { PaymentIntent } from "@stripe/stripe-js";

export const setPage = (page: number) => {
  return {
    type: "set_page",
    payload: page,
  };
};

export const setBedrooms = (bedrooms: string) => {
  return {
    type: "set_bedrooms",
    payload: bedrooms,
  };
};

export const setBathrooms = (bathrooms: string) => {
  return {
    type: "set_bathrooms",
    payload: bathrooms,
  };
};

export const setTypes = (types: string) => {
  return {
    type: "set_types",
    payload: types,
  };
};

export const getTotalPrice = () => {
  return {
    type: "get_total_price",
  };
};

export const getAppointmentValue = () => {
  return {
    type: "get_appointment_value",
  };
};

// step 2

export const setFlexibleTime = () => {
  return {
    type: "set_flexible_time",
  };
};

export const setDate = (date) => {
  return {
    type: "set_date",
    payload: date,
  };
};

export const setTime = (time) => {
  return {
    type: "set_time",
    payload: time,
  };
};

export const setOccurance = (occurance: string | number) => {
  return {
    type: "set_occurance",
    payload: occurance,
  };
};

export const setAddress = (address: string) => {
  return {
    type: "set_address",
    payload: address,
  };
};

export const setAppartment = (appartment: string) => {
  return {
    type: "set_appartment",
    payload: appartment,
  };
};

export const setAddons = (addon: any) => {
  return {
    type: "set_addon",
    payload: addon,
  };
};

export const setGetInType = (type: string, text: string = "") => {
  return {
    type: "set_getintype",
    payload: {
      type,
      text,
    },
  };
};

export const setPets = (pets: string, text: string = "") => {
  return {
    type: "set_pets",
    payload: { type: pets, text },
  };
};

export const setInstructions = (instructions: string) => {
  return {
    type: "set_instructions",
    payload: instructions,
  };
};

export const setPromocode = (code: string, codeDiscount: number) => {
  return {
    type: "set_promocode",
    payload: {
      code,
      codeDiscount,
    },
  };
};

export const setTip = (tip: number) => {
  return {
    type: "set_tip",
    payload: tip,
  };
};

export const setPaymentIntent = (paymentIntent: PaymentIntent) => {
  return {
    type: "set_payment_intent",
    payload: paymentIntent,
  };
};

export const setToken = (token: string, credits: number) => {
  return {
    type: "set_token",
    payload: { token, credits },
  };
};
