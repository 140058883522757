import { Layout } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { Cart } from "./Cart";
import { Logo } from "./Logo";
import { OrderSummary } from "./OrderSummary";

export const Sidebar = (props: any) => {
  const dispatch = useDispatch();
  const {
    types,
    bedrooms,
    bathrooms,
    codeDiscount,
    totalPrice,
    isFlexible,
    discount,
    occurance,
    addOns,
  } = useSelector(
    // @ts-ignore
    (state) => state.bookingReducer
  );

  const getSubtotal = useCallback((): number => {
    let subTotal =
      parseInt(bathrooms, 10) * 20 +
      parseInt(bedrooms, 10) * 20 +
      parseInt(types, 10);

    subTotal = subTotal - Math.round((occurance * subTotal) / 100);
    subTotal = subTotal + addOns.cost;
    subTotal = subTotal - (isFlexible ? 10 : 0);
    subTotal = subTotal - codeDiscount;
    return subTotal;
  }, [
    bathrooms,
    bedrooms,
    types,
    isFlexible,
    occurance,
    codeDiscount,
    addOns.cost,
  ]);

  let width = useResizeObserver();

  return (
    <Layout className="sidebar">
      {width > 768 ? (
        <OrderSummary types={types} bedrooms={bedrooms} bathrooms={bathrooms} />
      ) : null}
      <Cart
        subTotal={getSubtotal()}
        dispatch={dispatch}
        totalPrice={totalPrice}
        discount={discount}
      />
    </Layout>
  );
};

const SummaryContainer = styled.div`
  padding: 25px;
  background-color: #eefcff;
  h1 {
    margin-top: 1vh;
    font-weight: 600;
    color: #373744;
  }
  .blue {
    font-weight: 600;
    color: #323593;
  }
  color: #000;
`;

const CartContainer = styled.div`
  width: 100%;
  bottom: 0;
  background-color: #dff9ff;
  margin-top: 5vh;
  padding: 25px;
  color: #000;
  .blue {
    font-weight: 600;
    color: #323593;
  }
  @media screen and (max-width: 991px) {
    padding: 25px;
  }
`;

export { Logo, CartContainer, SummaryContainer };
