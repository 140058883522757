import { Button, Layout, Result, Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "src";
import { useConfirmation } from "src/context/confirmation";
import { verifyPayment } from "src/weirdStuff/api";

export default function VerifyPayments() {
  const [error, setError] = React.useState<string | null>(null);

  const navigate = useNavigate();

  const location = useLocation();

  const locationState = location.state as {
    paymentIntent: string;
    paymentMethod: string;
  };

  const {
    user: { token },
  } = useSelector((state: RootState) => state.bookingReducer);

  const payment_intent = locationState?.paymentIntent;
  const payment_method = locationState?.paymentMethod;

  const { confirmBooking } = useConfirmation();

  React.useEffect(() => {
    if (!payment_intent) return;

    window.onbeforeunload = null;

    verifyPayment({
      payment_intent,
      payment_type: "stripe",
      payment_method,
      token,
    })
      .then((result) => {
        if ("error" in result) {
          return setError(result.error as string);
        }

        if ("data" in result && result.data.total_amount) {
          confirmBooking(result.data);
          navigate("/confirmation", { replace: true });
        }
      })
      .catch((error) => {
        setError(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error)
    return (
      <div>
        <Result
          status="error"
          title={error || "Failed to verify payment"}
          subTitle="Please try again or contact support"
          extra={[
            // button to contact support
            <Button
              size="large"
              style={{ borderRadius: 5 }}
              key="contact"
              type="primary"
              href={`mailto:support@heyhomero.com?subject=Payment%20verification%20failed%20for%20Payment%20ID%20-%20${payment_intent}`}
            >
              Contact Support
            </Button>,
            // button to go back to home
            <Button
              size="large"
              style={{ borderRadius: 5 }}
              key="home"
              type="ghost"
              onClick={() => {
                navigate("/", { replace: true });
                window.location.href = "/";
              }}
            >
              Go Back
            </Button>,
          ]}
        />
      </div>
    );
  else {
    return (
      <Layout className="center step-layout">
        <Spin size="large" />
        <h1>Verifying Payment</h1>
        <p>Please wait while we verify your payment</p>
      </Layout>
    );
  }
}
