import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src";

export function usePrice() {
  const {
    types,
    bedrooms,
    bathrooms,
    codeDiscount,
    isFlexible,
    occurance,
    addOns,
  } = useSelector((state: RootState) => state.bookingReducer);

  const getSubtotal = useCallback((): number => {
    let subTotal =
      parseInt(bathrooms, 10) * 20 +
      parseInt(bedrooms, 10) * 20 +
      parseInt(types, 10);

    subTotal = subTotal - Math.round((occurance * subTotal) / 100);
    subTotal = subTotal + addOns.cost;
    subTotal = subTotal - (isFlexible ? 10 : 0);
    subTotal = subTotal - codeDiscount;
    return subTotal;
  }, [
    bathrooms,
    bedrooms,
    types,
    isFlexible,
    occurance,
    codeDiscount,
    addOns.cost,
  ]);

  const getTotal = useCallback((): number => {
    const subTotal = getSubtotal();
    const tax: number = Math.round((subTotal * 8.875) / 100);

    return subTotal + tax;
  }, [getSubtotal]);

  return {
    getSubtotal,
    getTotal,
  };
}
