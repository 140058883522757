import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";
import { Button, Col, Row } from "antd";
import Checkbox from "antd/es/checkbox";
import Search from "antd/es/input/Search";
import Layout from "antd/lib/layout/layout";
import React, { useCallback, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "src";
import { setPaymentIntent, setPromocode } from "src/actions";
import { logEvent } from "src/analytics/amplitude";
import { useConfirmation } from "src/context/confirmation";
import { VerifyCoupon } from "src/graphql/mutations/coupons";
import { useResizeObserver } from "src/hooks/useResizeObserver";
import { contactOptions } from "src/pages/StepFour";
import { openNotificationWithIcon } from "src/pages/StepTwo";
import { BookingInput } from "src/types";
import { userSchema } from "src/types/userSchema";
import { debounce, formatToPhone } from "src/weirdStuff";
import {
  createPaymentIntent,
  getBookingDetails,
  updatePaymentIntent,
  verifyPayment,
} from "src/weirdStuff/api";
import { useMutation } from "urql";
import { CheckoutForm } from "./utils/CheckoutForm";
import FormInput from "./utils/FormInput";
import { StyledRadioGroup } from "./utils/RadioGroup";
import Tip from "./utils/Tip";

const Payments = (props) => {
  const [status, setStatus] = React.useState("idle");
  const state = useSelector((state: RootState) => state.bookingReducer);
  const dispatch = useDispatch();

  const width = useResizeObserver();

  const { code } = state;

  const stripe = useStripe();
  const elements = useElements();

  const [, verifyCoupon] = useMutation(VerifyCoupon);

  const { confirmBooking } = useConfirmation();

  const navigate = useNavigate();

  const { control, formState, handleSubmit, watch } = useFormContext();

  const watchPaymentType = watch("paymentType", "stripe");
  const watchEmail = watch("email");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkForCredits = useCallback(
    debounce(
      (email: string) => state.occurance === 0 && props.checkEmail(email),
      3000
    ),
    []
  );

  useEffect(() => {
    if (watchEmail && !formState.errors["email"]) {
      checkForCredits(watchEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkForCredits, formState.errors?.email, watchEmail]);

  const isCouponValid = (code: string) => {
    setStatus("verifying coupon");
    if (state.occurance > 0) {
      logEvent("Coupon code not used since frequency is greater than once.");
      openNotificationWithIcon(
        "error",
        "Cannot apply coupons",
        "Coupon codes are not valid for weekly, bi-weekly or monthly occurrence"
      );
      dispatch(setPromocode("", 0));
      setStatus("idle");
      return;
    }
    verifyCoupon({ code, email: watchEmail }).then((result) => {
      setStatus("idle");
      if (result.error || !result.data) {
        logEvent("User entered an invalid promocode", { promocode: code });
        openNotificationWithIcon("error", "Invalid promocode", "");
        dispatch(setPromocode("", 0));
      } else {
        logEvent("User applied promocode", { promocode: code });
        openNotificationWithIcon(
          "success",
          "Promocode applied",
          "You just availed a discount of $" +
            result.data.verifyCoupon?.discount +
            "!"
        );
        dispatch(setPromocode(code, result.data?.verifyCoupon?.discount));
      }
    });
  };

  async function onSubmit(data: typeof userSchema.__inputType) {
    if (!data.terms) return;
    setStatus("booking");

    const booking: BookingInput = getBookingDetails({ state, ...data });

    if (data.tipAmount || data.tipOptions) {
      if (data.tipOptions === "other") {
        booking.tip = data.tipAmount;
      } else {
        booking.tip = Number(data.tipOptions);
      }
    }

    if (
      data.paymentType === "stripe" &&
      booking.total_amount! > state.user.credits
    ) {
      const cardElement = elements?.getElement(
        CardElement
      ) as StripeCardElement;
      const pm = await stripe?.createPaymentMethod({
        billing_details: {
          name: data.name,
          email: data.email,
          phone: data.phone,
        },
        type: "card",
        card: cardElement,
      });
      if (pm?.error) {
        setStatus("idle");
        return openNotificationWithIcon(
          "error",
          "Error",
          pm.error?.message ?? "Failed to validate card details"
        );
      } else {
        booking.payment_method_id = pm?.paymentMethod.id;
      }
    }

    // if we have a previously saved payment_intent, we might want to verify if it's upto date,
    // if not, we'll create a new payment_intent
    const paymentIntent = state.paymentIntent
      ? await updatePaymentIntent(
          state.paymentIntent,
          booking,
          state.user.token
        ).catch(() => setStatus("idle"))
      : await createPaymentIntent(booking, state.user.token).catch(() =>
          setStatus("idle")
        );

    if (!paymentIntent || "error" in paymentIntent) {
      console.error(paymentIntent?.error);
      logEvent("Payment setup failed", {
        user: data.fullname,
        error: paymentIntent?.error as string,
      });
      openNotificationWithIcon(
        "error",
        "Failed to complete booking",
        paymentIntent?.error as string
      );
      setStatus("idle");
      return;
    }

    // store the updated payment intent in the redux store
    dispatch(setPaymentIntent(paymentIntent));

    // if the payment type is "cash", we'll just confirm the booking
    if (
      data.paymentType === "cash" ||
      (booking.frequency === "One Time" &&
        booking.total_amount! <= state.user.credits)
    ) {
      const result = await verifyPayment({
        booking,
        payment_intent: paymentIntent.id,
        payment_type: "cash",
        token: state.user.token,
      });
      if ("error" in result) {
        setStatus("idle");
        openNotificationWithIcon(
          "error",
          "Payment failed",
          (result.error as string) || ""
        );
        return;
      }
      if (result.data?.total_amount) {
        // if the booking is successful, we'll send the user to the confirmation page
        confirmBooking({
          email: result.data.email,
          fullname: result.data.fullname,
          clean_date: result.data.clean_date,
          total_amount: result.data.total_amount,
        });
        navigate("/confirmation", { replace: true });
        return;
      }
      setStatus("idle");
      return openNotificationWithIcon(
        "error",
        "Payment failed",
        "Something went wrong"
      );
    }

    // For one-time booking, if payment status is 'requires_action' or
    // 'requires_payment_method', we'll need to confirm the card payment.
    if (
      paymentIntent.status === "requires_action" ||
      paymentIntent.status === "requires_payment_method" ||
      paymentIntent.status === "requires_confirmation"
    ) {
      const result = await stripe?.confirmCardPayment(
        paymentIntent.client_secret!,
        {
          payment_method: booking.payment_method_id,
        }
      );
      if (result?.error) {
        setStatus("idle");
        openNotificationWithIcon(
          "error",
          "Payment failed",
          result?.error?.message ?? "Something went wrong"
        );
        return;
      }
    }

    setStatus("idle");

    logEvent("Payment successful", {
      user: data.fullname,
      total_amount: booking.total_amount,
      clean_date: booking.clean_date,
    });
    confirmBooking({
      fullname: data.fullname as string,
      email: data.email as string,
      clean_date: booking.clean_date,
      total_amount: booking.total_amount as number,
    });
    navigate("/payments/verify", {
      state: {
        paymentMethod: booking.payment_method_id,
        paymentIntent: paymentIntent.id,
      },
      replace: true,
    });
  }

  return (
    <Layout className="step-layout">
      <h1 className="semi-bold">Alright, Let's get this wrapped up.</h1>
      <p>Now just enter your contact and payment details for Homero.</p>
      <h3 className="mt-2 semi-bold" style={{ marginBottom: 2 }}>
        Your Details
      </h3>
      {/* @ts-ignore */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row
          gutter={{ xs: 0, md: 16 }}
          style={{
            marginTop: 5,
          }}
        >
          <Col xs={24} md={12}>
            <FormInput label="Full Name" id="fullname" type="text" />
          </Col>
          <Col xs={24} md={12}>
            <FormInput label="Email" id="email" type="email" />
          </Col>
          <Col xs={24} lg={12}>
            <FormInput
              label="Phone Number"
              autoComplete="tel"
              prefix="+1"
              id="phone"
              type="tel"
              onChange={(e) => formatToPhone(e)}
            />
          </Col>
          <Col xs={24} lg={12}>
            <StyledRadioGroup
              id="contactType"
              label="Contact Type"
              size="small"
              options={contactOptions}
            />
          </Col>
        </Row>
        <br />
        <br />
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <label className="mt-2">Promo Code</label>
            <Search
              enterButton={
                status === "verifying coupon"
                  ? "Applying coupon..."
                  : "Apply coupon"
              }
              defaultValue={code}
              onSearch={(value) => isCouponValid(value)}
              className="input"
              type="search"
              style={{ width: "100%", borderRadius: 6, border: "none" }}
              placeholder="Enter your promo code to avail discount"
              size="large"
            />
          </Col>
          <Col lg={12} xs={24}>
            <Tip />
          </Col>
        </Row>
        <br />
        {state.user.credits < state.totalPrice && (
          <>
            <h3 className="mt-2 mb-2 semi-bold">Payment Details</h3>
            <CheckoutForm cash={watchPaymentType === "cash"} />
          </>
        )}
        <br />
        <Controller
          name="terms"
          render={({ field: { value, onChange, ...field }, fieldState }) => (
            <Checkbox
              id="terms"
              style={fieldState.error ? { color: "#ff0000" } : {}}
              value={value}
              checked={!!value}
              onChange={onChange}
              {...field}
            >
              I agree to the{" "}
              <a
                href="https://heyhomero.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of service
              </a>
            </Checkbox>
          )}
          control={control}
        />
        <br />
        {formState.errors["terms"] && (
          <span style={{ color: "red" }}>
            {formState.errors["terms"].message}
          </span>
        )}
        <br /> <br />
        <div className="button-group w-full">
          {width < 768 ? (
            <Button
              disabled={!stripe || status === "booking"}
              type="primary"
              htmlType="submit"
              className="next-button first"
            >
              {status === "booking" ? "Please wait..." : "Book Appointment"}
            </Button>
          ) : null}
          <Button
            onClick={() => props.changePage(3)}
            type="primary"
            disabled={status === "booking"}
            className="next-button back-button second"
          >
            Back
          </Button>
          {width >= 768 ? (
            <Button
              disabled={!stripe || status === "booking"}
              htmlType="submit"
              type="primary"
              className="next-button first"
            >
              {status === "booking" ? "Please wait..." : "Book Appointment"}
            </Button>
          ) : null}
        </div>
      </form>
      <br />
    </Layout>
  );
};

export default Payments;
