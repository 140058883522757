import Checkbox, { CheckboxGroupProps } from "antd/es/checkbox";
import { Col, Row } from "antd/es/grid";
import { memo } from "react";
import { addOnOptions } from "src/constants/options";
import { useResizeObserver } from "src/hooks/useResizeObserver";
import styled from "styled-components";

interface Props extends CheckboxGroupProps {}

function AddOns({ defaultValue, onChange }: Props) {
  const width = useResizeObserver();

  const isMobile = width <= 500;

  return (
    <fieldset>
      <label className="mt-2" htmlFor="addOns">
        Add ons (Select any)
      </label>
      <StyledCheckboxWrapper
        name="addOns"
        defaultValue={defaultValue}
        onChange={onChange}
      >
        <Row gutter={16}>
          {addOnOptions.map((addOn) => {
            return (
              <Col span={isMobile ? 24 : 8} key={addOn.value}>
                <StyledCheckbox value={addOn.value}>
                  <div>
                    <img
                      style={{ width: 24, height: 24, marginRight: 12 }}
                      src={`${require("../../assets/" + addOn.image!).default}`}
                      alt={addOn.label}
                    />
                    <span
                      style={{ display: isMobile ? "block" : "inline-block" }}
                    >
                      {addOn.label} - ${addOn.cost}
                    </span>
                  </div>
                </StyledCheckbox>
              </Col>
            );
          })}
        </Row>
      </StyledCheckboxWrapper>
    </fieldset>
  );
}

export const StyledCheckboxWrapper = styled(Checkbox.Group)`
  width: 100%;
  label.ant-checkbox-wrapper-checked {
    background-color: var(--blue-300) !important;
    border: 1px solid var(--blue-300);
    color: white;
    transition: all 0.3s ease-in-out;
  }
  .ant-checkbox {
    top: 0;
  }
  @media (min-width: 500px) {
    span.ant-checkbox-inner {
      display: none;
    }
  }

  label.ant-checkbox-wrapper > span {
    text-align: left;
    /* word-break: keep-all; */
  }
`;

export const StyledCheckbox = styled(Checkbox)({
  color: "black",
  width: "100%",
  textAlign: "center",
  display: "flex",
  gap: "0.15rem",
  justifyContent: "flex-start",
  alignItems: "center",
  borderRadius: "6px",
  border: "1px solid #e2e2e2",
  boxShadow: "2px 2px 10px rgb(0 0 0 / 5%)",
  padding: "1rem",
  marginBottom: "0.5rem",
  marginTop: "0.5rem",
  "@media (max-width: 500px)": {
    "label.button": {
      padding: "0.5rem",
    },
    gap: "0.5rem",
  },
  div: {
    display: "flex",
    alignItems: "center",
  },
});

export default memo(AddOns);
