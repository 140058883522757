import React, { createContext, useContext, useReducer, Reducer } from "react";
import { IConfirmation, IConfirmationAction } from "../types/booking";

const initialState: IConfirmation = {
  fullname: "",
  email: "",
  clean_date: "",
  total_amount: 0,
};

const confirmationContext = createContext({
  ...initialState,
  confirmBooking: (data: IConfirmation) => {},
});

export const ConfirmationProvider = ({ children }) => {
  const value = useConfirmationProvider();
  return (
    <confirmationContext.Provider value={value}>
      {children}
    </confirmationContext.Provider>
  );
};

export const useConfirmation = () => useContext(confirmationContext);

const confirmationReducer = (
  state: IConfirmation,
  action: IConfirmationAction
) => {
  switch (action.type) {
    case "update":
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error("Invalid action type");
  }
};

export const useConfirmationProvider = () => {
  const [state, dispatch] = useReducer<
    Reducer<IConfirmation, IConfirmationAction>
  >(confirmationReducer, initialState);

  const confirmBooking = React.useCallback(
    (data: IConfirmation) => {
      dispatch({ type: "update", payload: data });
    },
    [dispatch]
  );

  return { ...state, confirmBooking };
};
