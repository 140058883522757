import Button from "antd/es/button";
import { Col, Row } from "antd/es/grid";
import Layout from "antd/es/layout";
import notification from "antd/es/notification";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import MobileNavbar from "src/components/utils/MobileNavbar";
import { useResizeObserver } from "src/hooks/useResizeObserver";
import { setDate } from "../actions";
import { logEvent } from "../analytics/amplitude";
import { Timer } from "../components/utils/Timer";

export const openNotificationWithIcon = (
  type: "success" | "error" | "info",
  title: string,
  description: string
) => {
  notification[type]({
    message: title,
    description,
  });
};

export default function StepTwo(props) {
  const dispatch = useDispatch();
  const { isFlexible, cleanDate, cleanTime } = useSelector(
    // @ts-ignore
    (state) => state.bookingReducer
  );

  const width = useResizeObserver();

  const isMobile = width <= 500;

  const next = () => {
    if ((cleanDate && isFlexible) || (cleanDate && cleanTime)) {
      logEvent("User picked a cleaning time", {
        cleanDate,
        cleanTime: isFlexible ? "flexible" : cleanTime,
      });
      props.changePage(3);
    } else {
      let notification = { description: "", title: "" };
      if (!cleanDate && !cleanTime && !isFlexible) {
        notification.description = "Please select the date and time";
        notification.title = "Error";
      } else if (!cleanDate) {
        notification.title = "Please select a date";
        notification.description = "Please select a date for your cleaning";
      } else if (!cleanTime) {
        notification.title = "Please select a time";
        notification.description = "Please select a time for your cleaning";
      }
      logEvent("User forgot to select a proper time", {
        cleanDate,
        cleanTime: isFlexible ? "flexible" : cleanTime,
      });
      openNotificationWithIcon(
        "error",
        notification.title,
        notification.description
      );
    }
  };

  const prev = () => {
    props.changePage(1);
  };

  return (
    <Layout className="step-layout mobile-step-layout">
      <h1 className="semi-bold">When should we clean?</h1>
      <br />
      <Layout style={{ backgroundColor: "white" }}>
        <Row>
          <Col lg={12} xs={24}>
            <p>Confirm Date</p>
            <Calendar
              calendarType="US"
              defaultValue={
                (!cleanDate ? new Date() : new Date(cleanDate)) ||
                new Date(new Date().setDate(new Date().getDate() + 1))
              }
              onChange={(v, e) => dispatch(setDate(moment(v).format()))}
              minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
              maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))}
              className="calendar"
              next2Label={isMobile ? null : undefined}
              prev2Label={isMobile ? null : undefined}
            />
            <br />
          </Col>
          <Col lg={12} xs={24}>
            <p>Confirm Time</p>
            <Timer isFlexible={isFlexible} cleanTime={cleanTime} />
          </Col>
        </Row>
      </Layout>
      {isFlexible ? (
        <>
          <br />
          <span className="center">
            Cleaner will arrive on selected date between 9am and 4pm. You will
            be notified about the exact time
          </span>
        </>
      ) : cleanTime ? (
        <>
          <br />
          <span className="center">
            Cleaner will arrive on selected date at {cleanTime}
          </span>
        </>
      ) : null}
      <br />
      {isMobile ? (
        <MobileNavbar nextAction={next} prevAction={prev} />
      ) : (
        <div className="button-group center">
          <Button
            onClick={() => {
              props.changePage(1);
            }}
            type="primary"
            className="next-button back-button"
          >
            Back
          </Button>
          <Button onClick={next} type="primary" className="next-button">
            Next
          </Button>
        </div>
      )}
      <br />
    </Layout>
  );
}
