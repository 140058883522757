export function getQueryParam(
  key: string,
  defaultValue: string,
  check: (value: string) => boolean
) {
  const query = new URLSearchParams(window.location.search).get(key);
  if (query && check(query)) {
    return new URLSearchParams(window.location.search).get(key)!;
  }
  return defaultValue;
}
