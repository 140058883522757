import { Col, Row, Tag } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setFlexibleTime, setTime } from "../../actions";

// build for 8:00am to 6:00pm

const periodSelector = {
  padding: "0 8px",
  backgroundColor: "#006bde",
  borderRadius: 5,
  color: "#fff",
};

const generateTimeSelectors = (period: string): string[] => {
  let halfHours = ["00", "30"];
  let times: string[] = [];
  let startHour = period === "am" ? 8 : 12;
  let endHour = period === "am" ? 11 : 18;
  for (let hour = startHour; hour <= endHour; hour++) {
    for (let minute = 0; minute < 2; minute++) {
      times.push(hour + ":" + halfHours[minute]);
    }
  }
  return times;
};

const formatTime = (period: string): string[] => {
  let times: string[] = generateTimeSelectors(period);
  let formatedTimes: string[] = [];
  let nHour: number, nMinute: string;
  for (let time of times) {
    [nHour, nMinute] = [parseInt(time.split(":")[0]), time.split(":")[1]];
    if (nHour < 12) {
      time += "am";
    } else {
      nHour = nHour % 12;
      time = (nHour || 12) + ":" + nMinute + "pm";
    }
    formatedTimes.push(time);
  }
  return formatedTimes;
};

const getPeriod = (time: string): string => {
  let periodArr: string[] = time.split("");
  return periodArr[periodArr.length - 2] + periodArr[periodArr.length - 1];
};

const TimerComponent = ({ isFlexible, cleanTime }) => {
  const [period, setPeriod] = React.useState<string>(
    getPeriod(cleanTime) || "am"
  );

  const dispatch = useDispatch();

  return (
    <div>
      <TimeLayout>
        <Row>
          <Period>
            <span
              style={period === "pm" ? periodSelector : {}}
              onClick={() => setPeriod("am")}
            >
              &lt;
            </span>
            {period}
            <span
              style={period === "am" ? periodSelector : {}}
              onClick={() => setPeriod("pm")}
            >
              &gt;
            </span>
          </Period>
          {formatTime(period).map((time, idx) => (
            <Col xs={period === "am" ? 12 : 8} key={idx}>
              <Time
                onClick={() => dispatch(setTime(time))}
                style={
                  cleanTime === time
                    ? {
                        backgroundColor: "var(--blue-300)",
                        color: "#fff",
                        borderColor: "var(--blue-300)",
                      }
                    : {}
                }
              >
                {time}
              </Time>
            </Col>
          ))}
          <Time
            style={
              isFlexible
                ? {
                    backgroundColor: "var(--blue-300)",
                    color: "#fff",
                    borderColor: "var(--blue-300",
                    margin: "15px auto",
                  }
                : {
                    margin: "15px auto",
                  }
            }
            onClick={() => dispatch(setFlexibleTime())}
          >
            &nbsp;I'm flexible &nbsp;&nbsp;
            <Tag color={isFlexible ? "var(--blue-200)" : "var(--blue-300)"}>
              Save $10
            </Tag>
          </Time>
        </Row>
      </TimeLayout>
    </div>
  );
};

export const Timer = React.memo(TimerComponent);

const TimeLayout = styled.div`
  display: block;
  padding: 10px;
  border-radius: 18px;
  border: 1px solid #d3d3d3;
`;

const Time = styled.span`
  display: block;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  margin: 5px;
  text-align: center;
  cursor: pointer;

  :hover,
  :focus,
  :active {
    background-color: var(--blue-100);
    color: #fff;
  }
  :active {
    background-color: var(--blue-200);
  }
`;

const Period = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 5px auto;
  align-items: center;
  color: var(--blue-200);

  span {
    cursor: pointer;
    font-size: 1.5em;
  }
`;
