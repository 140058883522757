import { ADD_ON_PRICE, CLEANING_TYPE_PRICE } from "src/constants/pricing";
import { getQueryParam } from "src/weirdStuff/state";

export let initialState = {
  page: 1,
  user: {
    token: undefined,
    credits: 0,
  },
  bedrooms: getQueryParam(
    "be",
    "0",
    (value) => Number(value) >= 0 && Number(value) <= 5
  ),
  bathrooms: getQueryParam(
    "ba",
    "1",
    (value) => Number(value) >= 1 && Number(value) <= 5
  ),
  types: getQueryParam("t", CLEANING_TYPE_PRICE.Standard, (value) =>
    Object.values(CLEANING_TYPE_PRICE).includes(value)
  ),
  isFlexible: true,
  totalPrice: 96.8,
  subTotal: Number(CLEANING_TYPE_PRICE.Standard),
  appointmentValue: Number(CLEANING_TYPE_PRICE.Standard),
  cleanDate: "",
  cleanTime: "",
  discount: 0,
  occurance: 15,
  address: "",
  appartmentNo: "",
  addOns: {
    type: [] as string[],
    cost: 0,
  },
  getInType: {
    type: "Someone in home",
    text: "",
  },
  pets: {
    type: "No Pets",
    text: "",
  },
  instructions: "",
  tip: 0,
  code: "",
  codeDiscount: 0,
  paymentIntent: null,
};

export const bookingReducer = (
  state = initialState,
  action: { type: string; payload?: number | string | undefined | Object | any }
) => {
  switch (action.type) {
    case "set_page":
      return {
        ...state,
        page: action.payload,
      };
    case "set_bedrooms":
      return {
        ...state,
        bedrooms: action.payload,
      };
    case "set_bathrooms":
      return {
        ...state,
        bathrooms: action.payload,
      };
    case "set_types":
      const cutOffPrice = Number(CLEANING_TYPE_PRICE["Move In / Out"]);
      const isAddonFree = parseInt(action.payload, 10) >= cutOffPrice;

      return {
        ...state,
        types: action.payload,
        addOns: {
          type: isAddonFree
            ? ["Inside Oven", "Inside Refrigerator", "Inside Cabinets"]
            : [],
          cost: 0,
        },
        occurance: Number(action.payload) >= cutOffPrice ? 0 : 15,
      };
    case "get_total_price":
      const {
        bedrooms,
        bathrooms,
        types,
        isFlexible,
        occurance,
        addOns,
        codeDiscount,
      } = state;
      let subTotal: number =
        parseInt(bedrooms, 10) * 20 +
        parseInt(bathrooms, 10) * 20 +
        parseInt(types, 10);
      subTotal = Math.round(subTotal - (occurance * subTotal) / 100);
      subTotal += addOns.cost;
      subTotal -= isFlexible ? 10 : 0;
      subTotal -= codeDiscount;

      const tax: number = Math.round((subTotal * 8.875) / 100);

      return {
        ...state,
        subTotal,
        totalPrice: subTotal + tax,
      };
    case "get_apppointment_value":
      return {
        ...state,
        appointmentValue:
          parseInt(state.bedrooms, 10) * 20 +
          parseInt(state.bathrooms, 10) * 20 +
          parseInt(state.types, 10),
      };
    case "set_flexible_time":
      const currentSubTotal = Number(state.subTotal);
      return {
        ...state,
        isFlexible: !state.isFlexible,
        cleanTime: "",
        subTotal: !state.isFlexible
          ? currentSubTotal - 10
          : currentSubTotal + 10,
        discount: !state.isFlexible ? state.discount + 10 : state.discount - 10,
      };
    case "set_date":
      return {
        ...state,
        cleanDate: action.payload,
      };
    case "set_time":
      return {
        ...state,
        isFlexible: false,
        cleanTime: action.payload,
      };
    case "set_occurance":
      return {
        ...state,
        occurance: action.payload,
      };
    case "set_address":
      return {
        ...state,
        address: action.payload,
      };
    case "set_appartment":
      return {
        ...state,
        appartmentNo: action.payload,
      };
    case "set_addon":
      let cost = 0;
      action.payload.forEach((type: string) => {
        if (type === "Organizing") {
          cost += Number(ADD_ON_PRICE.organizing);
        } else if (
          type === "Laundry wash & dry" ||
          type === "Interior Windows"
        ) {
          cost += Number(ADD_ON_PRICE.windows);
        } else if (
          parseInt(state.types, 10) >=
          Number(CLEANING_TYPE_PRICE["Move In / Out"])
        ) {
          cost += 0;
        } else {
          cost += Number(ADD_ON_PRICE.oven);
        }
      });
      return {
        ...state,
        addOns: {
          type: action.payload as string[],
          cost,
        },
      };
    case "set_getintype":
      return {
        ...state,
        getInType: action.payload,
      };
    case "set_pets":
      return {
        ...state,
        pets: action.payload,
      };
    case "set_instructions":
      return {
        ...state,
        instructions: action.payload,
      };
    case "set_promocode":
      return {
        ...state,
        code: action.payload.code,
        codeDiscount: action.payload.codeDiscount,
      };
    case "set_tip":
      return {
        ...state,
        tip: !isNaN(action.payload) ? action.payload : 0,
      };
    case "set_payment_intent":
      return {
        ...state,
        paymentIntent: action.payload,
      };
    case "set_token":
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
