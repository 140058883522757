import Divider from "antd/es/divider";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src";
import { CartContainer } from ".";
import { getTotalPrice } from "../../actions";

const getAppointmentValue = (
  bathrooms: string,
  bedrooms: string,
  types: string
) => {
  return (
    parseInt(bathrooms, 10) * 20 +
    parseInt(bedrooms, 10) * 20 +
    parseInt(types, 10)
  );
};

export const Cart = (props) => {
  const { subTotal } = props;

  const dispatch = useDispatch();
  const {
    totalPrice,
    isFlexible,
    occurance,
    bedrooms,
    bathrooms,
    types,
    addOns,
    tip,
    code,
    codeDiscount,
    user,
  } = useSelector((state: RootState) => state.bookingReducer);

  const getTax = React.useCallback(
    (): number => Math.round((subTotal * 8.88) / 100),
    [subTotal]
  );

  React.useEffect(() => {
    dispatch(getTotalPrice());
  }, [subTotal, totalPrice, codeDiscount, dispatch]);

  return (
    <CartContainer>
      <>
        <p className="flex justify-content-between">
          <span>Appointment Value:</span>
          <span className="blue">
            ${getAppointmentValue(bathrooms, bedrooms, types)}
          </span>
        </p>
        {isFlexible || occurance || code ? (
          <p className="flex justify-content-between">
            <span>Discount:</span>
            <span className="blue">
              -$
              {(isFlexible ? 10 : 0) +
                (occurance * getAppointmentValue(bathrooms, bedrooms, types)) /
                  100 +
                codeDiscount}
            </span>
          </p>
        ) : null}
        {addOns.cost ? (
          <p className="flex justify-content-between">
            <span>Add Ons:</span>
            <span className="blue">
              +$
              {addOns.cost}
            </span>
          </p>
        ) : null}
        <Divider style={{ color: "#B1DCE5" }} />
      </>
      <p className="flex justify-content-between">
        <span className="blue">Subtotal:</span>
        <span className="blue">$ {subTotal}</span>
      </p>

      <p className="flex justify-content-between">
        <span className="blue">Tax:</span>
        <span className="blue">$ {getTax()}</span>
      </p>

      <p className="flex justify-content-between">
        <span className="blue">Tip:</span>
        <span className="blue">$ {tip}</span>
      </p>

      <Divider style={{ color: "#B1DCE5" }} />

      {user.credits > 0 ? (
        <>
          <p className="flex justify-content-between">
            <span className="blue">Credits:</span>
            <span className="blue">
              ${Math.min(totalPrice, user.credits)} (
              {Math.max(0, user.credits - totalPrice)} remaining)
            </span>
          </p>
          <Divider style={{ color: "#B1DCE5" }} />
        </>
      ) : null}

      <p className="flex justify-content-between">
        <span className="blue">Total:</span>
        <span className="blue">
          $ {Math.max(0, totalPrice + tip - Math.min(totalPrice, user.credits))}
        </span>
      </p>
    </CartContainer>
  );
};
