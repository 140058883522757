import { gql } from "urql";

export const SEND_OTP = gql`
  mutation SendOtp($email: String!, $appType: String!) {
    otp: startPasswordless(email: $email, appType: $appType) {
      email
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOtp($email: String!, $otp: String!) {
    otp: verifyPasswordlessOtp(username: $email, otp: $otp) {
      access_token
      refresh_token
    }
  }
`;
