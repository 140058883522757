import Button from "antd/es/button";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src";
import { usePrice } from "src/hooks/usePrice";
import styled from "styled-components";

export default function MobileNavbar({
  nextAction,
  prevAction,
  nextText,
  prevText,
  loading,
}: {
  nextAction: () => void;
  prevAction: () => void;
  nextText: string;
  prevText: string;
  loading: boolean;
}) {
  const { page } = useSelector((state: RootState) => state.bookingReducer);

  const { getSubtotal } = usePrice();

  const subTotal = useMemo(() => getSubtotal(), [getSubtotal]);

  return (
    <Footer>
      <FooterContent>
        {page > 1 && <BackButton onClick={prevAction}>{prevText}</BackButton>}
        <Price>
          <small>Subtotal</small>
          <br />
          <h2>${subTotal}</h2>
        </Price>
        <NextButton
          disabled={loading}
          loading={loading}
          type="primary"
          onClick={nextAction}
        >
          {nextText}
        </NextButton>
      </FooterContent>
    </Footer>
  );
}

const defaultProps = {
  nextAction: () => {},
  prevAction: () => {},
  nextText: "Next",
  prevText: "Back",
  loading: false,
};

MobileNavbar.defaultProps = defaultProps;

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #d3f7ff;
  padding: 0.5rem 1rem;
  z-index: 1;
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.2);
`;

const NextButton = styled(Button)`
  background-color: var(--blue-200);
  color: #fff;
  height: auto;
  padding: 0.5rem 1.5rem;
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 1rem;
  width: auto;

  .ant-btn-loading {
    padding-left: 1rem;
  }
`;

const BackButton = styled(NextButton)`
  border-color: var(--blue-200);
  border: 1px solid;
  color: var(--blue-200);
  background-color: white;
`;

const Price = styled.div`
  h2 {
    font-weight: 600;
    line-height: 1;
    color: var(--blue-300);
  }
  text-align: left;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
