import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "antd/dist/antd.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { createClient, Provider as UrqlProvider } from "urql";
import { initAmplitude } from "./analytics/amplitude";
import App from "./App";
import "./index.css";
import { bookingReducer } from "./reducers";
import * as serviceWorker from "./serviceWorker";
import { InitialStateType } from "./types";

Sentry.init({
  dsn: process.env.REACT_APP_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const composeEnhancers =
  (typeof window !== "undefined" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const rootReducer = combineReducers<InitialStateType>({ bookingReducer });

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof rootReducer>;

const client = createClient({
  url: process.env.REACT_APP_GRAPHQL_URL!,
});

initAmplitude();

ReactDOM.render(
  <UrqlProvider value={client}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </UrqlProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
